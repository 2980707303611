var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-check" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.subrogated,
                expression: "subrogated"
              }
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: _vm.id, name: _vm.name },
            domProps: {
              checked: Array.isArray(_vm.subrogated)
                ? _vm._i(_vm.subrogated, null) > -1
                : _vm.subrogated
            },
            on: {
              change: function($event) {
                var $$a = _vm.subrogated,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.subrogated = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.subrogated = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.subrogated = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: _vm.id } },
            [_vm._v(_vm._s(_vm.labelCheckbox))]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.subrogated
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [_vm._t("default")], 2)
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }