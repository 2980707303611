var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.forms.user_id,
          expression: "forms.user_id"
        }
      ],
      attrs: {
        type: "hidden",
        id: "user_id",
        name: "user_id",
        placeholder: _vm.labels.user_id
      },
      domProps: { value: _vm.forms.user_id },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.forms, "user_id", $event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-floating" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.forms.document,
                expression: "forms.document"
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: ["###.###.###-##", "##.###.###/####-##"],
                expression: "['###.###.###-##', '##.###.###/####-##']"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              required: "required",
              id: "document",
              name: "document",
              placeholder: _vm.labels.document
            },
            domProps: { value: _vm.forms.document },
            on: {
              change: function($event) {
                return _vm.getCompany()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.forms, "document", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "document" } }, [
            _vm._v(_vm._s(_vm.labels.document))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "form-floating" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forms.company,
                  expression: "forms.company"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                required: "required",
                id: "company",
                name: "company",
                placeholder: _vm.labels.company
              },
              domProps: { value: _vm.forms.company },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.forms, "company", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "company" } }, [
              _vm._v(_vm._s(_vm.labels.company))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "form-floating" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.forms.role,
                    expression: "forms.role"
                  }
                ],
                staticClass: "form-control",
                attrs: { required: "required", id: "role", name: "role" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.forms,
                      "role",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v(_vm._s(_vm.labels.select))
                ]),
                _vm._v(" "),
                _vm._l(_vm.roles, function(role) {
                  return _c(
                    "option",
                    { key: role.id, domProps: { value: role.id } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(role.name) +
                          "\n                    "
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("label", { attrs: { for: "role" } }, [
              _vm._v(_vm._s(_vm.labels.role))
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }