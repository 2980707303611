/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";

import VueGoodTablePlugin from 'vue-good-table';
import Vue2Editor from "vue2-editor";
import VueSidebarMenu from 'vue-sidebar-menu'
import VueTheMask from 'vue-the-mask'
import VueStripeMenu from 'vue-stripe-menu'

import 'vue-good-table/dist/vue-good-table.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.use(VueSidebarMenu);
Vue.use(VueTheMask);
Vue.use(VueStripeMenu);
Vue.use(Vue2Editor);
Vue.use(VueGoodTablePlugin);

// ChatsJS
Vue.component(
    "bar-charts-component",
    require("./components/dashboard/barComponent.vue").default
);
Vue.component(
    "line-charts-component",
    require("./components/dashboard/lineComponent.vue").default
);

// Speedometer
Vue.component(
    "speedometer-component",
    require("./components/dashboard/speedometerComponent").default
);

// Price Tables
Vue.component(
    "masked-price-component",
    require("./components/price-table/maskedPriceComponent").default
);

// Users
Vue.component(
    "users-contracts-component",
    require("./components/users/UsersContractsComponent.vue").default
);
Vue.component(
    "contracts-component",
    require("./components/users/ContractsComponent.vue").default
);

// Airports
Vue.component(
    "charts-select-component",
    require("./components/airports/chartsSelectComponent.vue").default
);
//Price Table
Vue.component(
    "priceline-select-component",
    require("./components/price-table/pricelineSelectComponent.vue").default
);
// Contracts
Vue.component(
    "amount-exempted-aircraft-component",
    require("./components/contracts/amountExemptedAircraftComponent.vue")
        .default
);
Vue.component(
    "charts-subrogated-component",
    require("./components/contracts/chartsSubrogatedComponent.vue").default
);
Vue.component(
    "form-contracts-subrogated-component",
    require("./components/contracts/formContractsSubrogatedComponent.vue")
        .default
);
Vue.component(
    "contract-component",
    require("./components/contracts/contractComponent.vue").default
);

Vue.component(
    "company-component",
    require("./components/contracts/companyComponent.vue").default
);
// Hangar
Vue.component(
    "worksheet-component",
    require("./components/hangar/worksheetComponent.vue").default
);
// Aircraft
Vue.component(
    "prefix-component",
    require("./components/aircraft/prefixComponent.vue").default
);

const app = new Vue({
    el: "#app",
    data: {
        isOpen: false,
        allotment: null,
        boolean: false,
        boolean_quantity: false
    },
    created() {
        this.isOpen = localStorage.getItem("isDrawer") === "true";
    },
    methods: {
        async onToggleCollapse(collapsed) {
            this.isOpen = collapsed;
            await localStorage.setItem("isDrawer", collapsed);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatLiters(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        setBoolean(params, comparative) {
            console.log(params, comparative, params == comparative);
            this.boolean = params == comparative;
        }
    }
});
