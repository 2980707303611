<template>
    <div>
        <div class="row" v-for="(input, index) in forms" :key="`line-${index}`">
            <div class="col-12 row p-0 mb-3">
                <div
                    class="col-12 form-inline d-grid gap-2 d-flex justify-content-end p-0"
                >
                    <button
                        v-if="index === 0"
                        class="btn btn-primary mt-3 pt-2"
                        type="button"
                        @click="addField(input, forms)"
                    >
                        <i class="fas fa-plus"></i>
                    </button>
                    <button
                        v-else
                        class="btn btn-danger mt-3 pt-2"
                        type="button"
                        @click="removeField(input, forms)"
                    >
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-floating mb-3">
                            <select
                                v-model="input.contract"
                                class="form-select"
                                :id="`allotment[${index}][contract]`"
                                :name="`allotments[${index}][contract]`"
                                :aria-label="labelContract"
                            >
                                <option
                                    v-for="(option, index) in optionsContracts"
                                    :key="index"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                            <label :for="`allotment[${index}][contract]`">{{
                                labelContract
                            }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-floating mb-3">
                            <select
                                v-model="input.hangar"
                                class="form-select"
                                :id="`allotment[${index}][hangar]`"
                                :name="`allotments[${index}][hangar]`"
                                :aria-label="labelHangar"
                            >
                                <template
                                    v-for="(filter, key) in filterAllotment"
                                >
                                    <template
                                        v-for="(option,
                                        index) in optionsHangars"
                                    >
                                        <option
                                            v-if="
                                                filter.contract_id ==
                                                    input.contract &&
                                                    option.id ==
                                                        filter.hangar_id
                                            "
                                            :key="key + index"
                                            :value="option.id"
                                            >{{ option.name }}</option
                                        >
                                    </template>
                                </template>
                            </select>
                            <label :for="`allotment[${index}][hangar]`">{{
                                labelHangar
                            }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        //   Contract
        labelContract: {
            type: String,
            required: true
        },
        dataContracts: {
            type: Array,
            required: true
        },
        // Hangar
        labelHangar: {
            type: String,
            required: true
        },
        dataHangars: {
            type: Array,
            required: true
        },
        // allotments
        allotments: {
            type: Array
        },
        filter: {
            type: Array
        },
        edit: {
            type: Array
        }
    },
    created() {
        if (this.allotments) {
            this.allotments.map((allotment, key) => {
                this.forms[key] = {
                    hangar: `${allotment.hangar.id}`,
                    contract: `${allotment.contract.id}`
                };
            });
        }
    },
    data() {
        return {
            forms: [{ hangar: "", contract: "" }],
            optionsContracts: this.dataContracts,
            optionsHangars: this.dataHangars,
            filterAllotment: this.filter
        };
    },
    methods: {
        addField(value, fieldType) {
            fieldType.push({ hangar: "", contract: "" });
        },
        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        }
    }
};
</script>
