<template>
  <div>
    <div
      class="row mb-3"
      v-for="(input, index) in forms"
      :key="`line-${index}`"
    >
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <div class="row">
              <div class="col-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    :id="`hangars[${index}][name]`"
                    :name="`hangars[${index}][name]`"
                    v-model="input.hangar"
                    :placeholder="labelHangar"
                  />
                  <label :for="`hangars[${index}][name]`">{{
                    labelHangar
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 form-inline d-grid gap-2 d-flex justify-content-md-end"
          >
            <button
              v-if="index === 0"
              class="btn btn-primary"
              type="button"
              @click="addField(forms)"
            >
              <i class="fas fa-plus"></i>
            </button>
            <button
              v-else
              class="btn btn-danger"
              type="button"
              @click="removeField(input, forms)"
            >
              <i class="fas fa-times fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelHangar: {
      type: String,
      required: true,
    },
    labelFile: {
      type: String,
      required: true,
    },
    dataHangars: {
      type: Array,
    },
  },
  created() {
    if (this.dataHangars) {
      this.dataHangars.map((hangar, key) => {
        this.forms[key] = {
          hangar: hangar.name,
          file: hangar.link,
        };
      });
    }
  },
  data() {
    return {
      forms: [{ hangar: "", file: "" }],
    };
  },
  methods: {
    addField(fieldType) {
      fieldType.push({ hangar: "", file: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
  },
};
</script>
