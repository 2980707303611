var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.forms, function(input, index) {
      return _c("div", { key: "line-" + index, staticClass: "row" }, [
        _c("div", { staticClass: "col-12 row p-0 mb-3" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 form-inline d-grid gap-2 d-flex justify-content-end p-0"
            },
            [
              index === 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mt-3 pt-2",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.addField(input, _vm.forms)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-plus" })]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-danger mt-3 pt-2",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.removeField(input, _vm.forms)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-times" })]
                  )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" },
              [
                _c("div", { staticClass: "form-floating mb-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: input.contract,
                          expression: "input.contract"
                        }
                      ],
                      staticClass: "form-select",
                      attrs: {
                        id: "allotment[" + index + "][contract]",
                        name: "allotments[" + index + "][contract]",
                        "aria-label": _vm.labelContract
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            input,
                            "contract",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.optionsContracts, function(option, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: option.id } },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(option.name) +
                              "\n                            "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "allotment[" + index + "][contract]" } },
                    [_vm._v(_vm._s(_vm.labelContract))]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" },
              [
                _c("div", { staticClass: "form-floating mb-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: input.hangar,
                          expression: "input.hangar"
                        }
                      ],
                      staticClass: "form-select",
                      attrs: {
                        id: "allotment[" + index + "][hangar]",
                        name: "allotments[" + index + "][hangar]",
                        "aria-label": _vm.labelHangar
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            input,
                            "hangar",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _vm._l(_vm.filterAllotment, function(filter, key) {
                        return [
                          _vm._l(_vm.optionsHangars, function(option, index) {
                            return [
                              filter.contract_id == input.contract &&
                              option.id == filter.hangar_id
                                ? _c(
                                    "option",
                                    {
                                      key: key + index,
                                      domProps: { value: option.id }
                                    },
                                    [_vm._v(_vm._s(option.name))]
                                  )
                                : _vm._e()
                            ]
                          })
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "allotment[" + index + "][hangar]" } },
                    [_vm._v(_vm._s(_vm.labelHangar))]
                  )
                ])
              ]
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }