<template>
  <div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            :id="id"
            :name="name"
            v-model="subrogated"
          />
          <label class="form-check-label" :for="id">{{ labelCheckbox }}</label>
        </div>
      </div>
    </div>
    <div v-if="subrogated">
      <div class="row">
        <div class="col-12">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelCheckbox: {
      type: String,
    },
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  created() {
    if (this.value != "" && (this.value === "on" || this.value === "1")) {
      this.subrogated = true;
    }
  },
  data() {
    return {
      subrogated: false,
    };
  },
};
</script>

<style>
</style>
