var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.forms, function(input, index) {
        return _c("div", { key: "line-" + index, staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11"
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "form-floating" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: input.peso,
                              expression: "input.peso"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "prices[" + index + "][peso]",
                            name: "prices[" + index + "][peso]",
                            placeholder: "Peso"
                          },
                          domProps: { value: input.peso },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(input, "peso", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "prices[" + index + "][peso]" } },
                          [_vm._v("Peso")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "form-floating" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: input.usd,
                              expression: "input.usd"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: "prices[" + index + "][usd]",
                            name: "prices[" + index + "][usd]",
                            placeholder: "USD"
                          },
                          domProps: { value: input.usd },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(input, "usd", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "prices[" + index + "][usd]" } },
                          [_vm._v("USD")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "form-floating" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: input.brl,
                              expression: "input.brl"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: "prices[" + index + "][brl]",
                            name: "prices[" + index + "][brl]",
                            placeholder: "BRL"
                          },
                          domProps: { value: input.brl },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(input, "brl", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "prices[" + index + "][brl]" } },
                          [_vm._v("BRL")]
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 form-inline d-grid gap-2 d-flex justify-content-md-end"
                },
                [
                  index === 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.addField(_vm.forms)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-plus" })]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeField(input, _vm.forms)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times fa-lg" })]
                      )
                ]
              )
            ])
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [_vm._v("Peso de Aeronave")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _vm._v("Valor em U$ para aeronaves internacionais")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _vm._v("Valor em R$ para aeronaves nacionais")
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }