var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.forms, function(input, index) {
      return _c("div", { key: "line-" + index, staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-floating" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: input.hangar,
                            expression: "input.hangar"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "hangars[" + index + "][name]",
                          name: "hangars[" + index + "][name]",
                          placeholder: _vm.labelHangar
                        },
                        domProps: { value: input.hangar },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(input, "hangar", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "hangars[" + index + "][name]" } },
                        [_vm._v(_vm._s(_vm.labelHangar))]
                      )
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 form-inline d-grid gap-2 d-flex justify-content-md-end"
              },
              [
                index === 0
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.addField(_vm.forms)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-plus" })]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.removeField(input, _vm.forms)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-times fa-lg" })]
                    )
              ]
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }