var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "vue-table",
        {
          attrs: {
            "tbody-data": _vm.products,
            headers: _vm.headers,
            "custom-options": _vm.customOptions,
            "style-wrap-vue-table": _vm.styleWrapVueTable,
            "disable-cells": _vm.disableCells,
            "disable-sort-thead": _vm.disableSortThead,
            loading: _vm.loading,
            "parent-scroll-element": _vm.parentScrollElement,
            "select-position": _vm.selectPosition,
            "submenu-tbody": _vm.submenuTbody,
            "submenu-thead": _vm.submenuThead
          },
          on: {
            "tbody-change-data": _vm.changeData,
            "tbody-submenu-click-change-color": _vm.changeColorTbody,
            "tbody-submenu-click-change-value": _vm.changeValueTbody,
            "thead-submenu-click-change-color": _vm.changeColorThead,
            "thead-submenu-click-change-value": _vm.changeValueThead,
            "thead-td-sort": _vm.sortProduct
          }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Specific Header")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "loader" }, slot: "loader" }, [
            _vm._v("Loader")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }