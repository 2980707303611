var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.forms, function(input, index) {
      return _c("div", { key: "line-" + index }, [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.labels.contract) +
                "\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: input.id,
              expression: "input.id"
            }
          ],
          attrs: {
            type: "hidden",
            id: "contracts[" + index + "][id]",
            name: "contracts[" + index + "][id]"
          },
          domProps: { value: input.id },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(input, "id", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c("div", { staticClass: "form-floating" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: input.name,
                      expression: "input.name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    required: "required",
                    id: "contracts[" + index + "][name]",
                    name: "contracts[" + index + "][name]",
                    placeholder: _vm.labels.name
                  },
                  domProps: { value: input.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(input, "name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { attrs: { for: "contracts[" + index + "][name]" } },
                  [_vm._v(_vm._s(_vm.labels.name))]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c("div", { staticClass: "form-floating" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: input.price_table,
                        expression: "input.price_table"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "required",
                      id: "contracts[" + index + "][price_table]",
                      name: "contracts[" + index + "][price_table]"
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          input,
                          "price_table",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.labels.select))
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.tables, function(table) {
                      return _c(
                        "option",
                        { key: table.id, domProps: { value: table.id } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(table.name) +
                              "\n                        "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { attrs: { for: "contracts[" + index + "][price_table]" } },
                  [_vm._v(_vm._s(_vm.labels.priceTable))]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            { staticClass: "col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
            [
              _c("div", { staticClass: "form-floating" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: input.icao,
                        expression: "input.icao"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "required",
                      id: "contracts[" + index + "][icao]",
                      name: "contracts[" + index + "][icao]"
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            input,
                            "icao",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function($event) {
                          return _vm.getHangars(input, index)
                        }
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.labels.select))
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.airports, function(airport) {
                      return _c(
                        "option",
                        { key: airport.id, domProps: { value: airport.id } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(airport.ICAO) +
                              "\n                        "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { attrs: { for: "contracts[" + index + "][icao]" } },
                  [_vm._v("ICAO")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
            [
              _c("div", { staticClass: "form-floating" }, [
                _c("div", { staticClass: "form-floating" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: input.hangar,
                          expression: "input.hangar"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        required: "required",
                        id: "contracts[" + index + "][hangar]",
                        name: "contracts[" + index + "][hangar]"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            input,
                            "hangar",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.labels.select))
                      ]),
                      _vm._v(" "),
                      _vm._l(input.hangars_list, function(hangar) {
                        return _c(
                          "option",
                          { key: hangar.id, domProps: { value: hangar.id } },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(hangar.name) +
                                "\n                            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "contracts[" + index + "][hangar]" } },
                    [_vm._v("Hangar")]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
            [
              _c("div", { staticClass: "form-floating" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: input.date,
                      expression: "input.date"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "date",
                    required: "required",
                    id: "contracts[" + index + "][date]",
                    name: "contracts[" + index + "][date]",
                    placeholder: _vm.labels.date
                  },
                  domProps: { value: input.date },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(input, "date", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { attrs: { for: "contracts[" + index + "][date]" } },
                  [_vm._v(_vm._s(_vm.labels.date))]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
            [
              _c("div", { staticClass: "form-floating" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: input.index,
                        expression: "input.index"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "required",
                      id: "contracts[" + index + "][index]",
                      name: "contracts[" + index + "][index]"
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          input,
                          "index",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.labels.select))
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "igpdi" } }, [
                      _vm._v("IGPDI")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "ipca" } }, [_vm._v("IPCA")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { attrs: { for: "contracts[" + index + "][index]" } },
                  [_vm._v(_vm._s(_vm.labels.index))]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "contracts[" + index + "][file]" }
                },
                [_vm._v(_vm._s(_vm.labels.file))]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: "file",
                  id: "contracts[" + index + "][file]",
                  name: "contracts[" + index + "][file]",
                  placeholder: _vm.labels.file,
                  required: input.id == "",
                  autofocus: "true"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: input.unique_price,
                          expression: "input.unique_price"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        name: "contracts[" + index + "][unique_price]",
                        id: "contracts[" + index + "][unique_price]"
                      },
                      domProps: {
                        checked: Array.isArray(input.unique_price)
                          ? _vm._i(input.unique_price, null) > -1
                          : input.unique_price
                      },
                      on: {
                        change: function($event) {
                          var $$a = input.unique_price,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  input,
                                  "unique_price",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  input,
                                  "unique_price",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(input, "unique_price", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "contracts[" + index + "][unique_price]" }
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.labels.specific_price) +
                            "\n                            "
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3" }, [
                input.unique_price
                  ? _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          },
                          [
                            _c("div", { staticClass: "form-floating" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: input.price_real,
                                    expression: "input.price_real"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "required",
                                  id: "contracts[" + index + "][price_real]",
                                  name: "contracts[" + index + "][price_real]",
                                  placeholder: "Real (R$)"
                                },
                                domProps: { value: input.price_real },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      input,
                                      "price_real",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "contracts[" + index + "][price_real]"
                                  }
                                },
                                [_vm._v("Real (R$)")]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          },
                          [
                            _c("div", { staticClass: "form-floating" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: input.price_dolar,
                                    expression: "input.price_dolar"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "required",
                                  id: "contracts[" + index + "][price_dolar]",
                                  name: "contracts[" + index + "][price_dolar]",
                                  placeholder: _vm.labels.dollar + " (U$)"
                                },
                                domProps: { value: input.price_dolar },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      input,
                                      "price_dolar",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "contracts[" + index + "][price_dolar]"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.labels.dollar) + " (U$)")]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: input.quantity_boolean,
                          expression: "input.quantity_boolean"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        name: "contracts[" + index + "][quantity_boolean]",
                        id: "contracts[" + index + "][quantity_boolean]"
                      },
                      domProps: {
                        checked: Array.isArray(input.quantity_boolean)
                          ? _vm._i(input.quantity_boolean, null) > -1
                          : input.quantity_boolean
                      },
                      on: {
                        change: function($event) {
                          var $$a = input.quantity_boolean,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  input,
                                  "quantity_boolean",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  input,
                                  "quantity_boolean",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(input, "quantity_boolean", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: {
                          for: "contracts[" + index + "][quantity_boolean]"
                        }
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.labels.specific_price_quantity) +
                            "\n                            "
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3" }, [
                input.quantity_boolean
                  ? _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                          },
                          [
                            _c("div", { staticClass: "form-floating" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: input.quantity,
                                    expression: "input.quantity"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  required: "required",
                                  id: "contracts[" + index + "][quantity]",
                                  name: "contracts[" + index + "][quantity]",
                                  placeholder: _vm.labels.quantity
                                },
                                domProps: { value: input.quantity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      input,
                                      "quantity",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "contracts[" + index + "][quantity]"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.labels.quantity))]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                          },
                          [
                            _c("div", { staticClass: "form-floating" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: input.price_real_quantity,
                                    expression: "input.price_real_quantity"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "required",
                                  id:
                                    "contracts[" +
                                    index +
                                    "][price_real_quantity]",
                                  name:
                                    "contracts[" +
                                    index +
                                    "][price_real_quantity]",
                                  placeholder: "Real (R$)"
                                },
                                domProps: { value: input.price_real_quantity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      input,
                                      "price_real_quantity",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for:
                                      "contracts[" +
                                      index +
                                      "][price_real_quantity]"
                                  }
                                },
                                [_vm._v("Real (R$)")]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                          },
                          [
                            _c("div", { staticClass: "form-floating" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: input.price_dolar_quantity,
                                    expression: "input.price_dolar_quantity"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "required",
                                  id:
                                    "contracts[" +
                                    index +
                                    "][price_dolar_quantity]",
                                  name:
                                    "contracts[" +
                                    index +
                                    "][price_dolar_quantity]",
                                  placeholder: _vm.labels.dollar + " (U$)"
                                },
                                domProps: { value: input.price_dolar_quantity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      input,
                                      "price_dolar_quantity",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for:
                                      "contracts[" +
                                      index +
                                      "][price_dolar_quantity]"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.labels.dollar) + " (U$)")]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12 col-sm-6 col-md-6 col-lg-4" }, [
            index === 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary form-control",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.addField(_vm.forms)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-plus" }),
                    _vm._v(
                      " " + _vm._s(_vm.labels.contract) + "\n                "
                    )
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-danger form-control text-white",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.removeField(index, _vm.forms)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-times fa-lg" }),
                    _vm._v(
                      " " + _vm._s(_vm.labels.contract) + "\n                "
                    )
                  ]
                )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }