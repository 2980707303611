<template>
    <div>
        <div v-for="(input, index) in forms" :key="`line-${index}`">
            <div class="row mb-3">
                <div class="col-12">
                    {{ labels.contract }}
                </div>
            </div>

            <input
                type="hidden"
                :id="`contracts[${index}][id]`"
                :name="`contracts[${index}][id]`"
                v-model="input.id"
            />

            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            required="required"
                            :id="`contracts[${index}][name]`"
                            :name="`contracts[${index}][name]`"
                            v-model="input.name"
                            :placeholder="labels.name"
                        />
                        <label :for="`contracts[${index}][name]`">{{
                            labels.name
                        }}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-floating">
                        <select
                            class="form-control"
                            required="required"
                            :id="`contracts[${index}][price_table]`"
                            :name="`contracts[${index}][price_table]`"
                            v-model="input.price_table"
                        >
                            <option value="">{{ labels.select }}</option>
                            <option
                                v-for="table in tables"
                                :key="table.id"
                                :value="table.id"
                            >
                                {{ table.name }}
                            </option>
                        </select>
                        <label :for="`contracts[${index}][price_table]`">{{
                            labels.priceTable
                        }}</label>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <div class="form-floating">
                        <select
                            class="form-control"
                            required="required"
                            :id="`contracts[${index}][icao]`"
                            :name="`contracts[${index}][icao]`"
                            v-model="input.icao"
                            v-on:change="getHangars(input, index)"
                        >
                            <option value="">{{ labels.select }}</option>
                            <option
                                v-for="airport in airports"
                                :key="airport.id"
                                :value="airport.id"
                            >
                                {{ airport.ICAO }}
                            </option>
                        </select>
                        <label :for="`contracts[${index}][icao]`">ICAO</label>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <div class="form-floating">
                        <div class="form-floating">
                            <select
                                class="form-control"
                                required="required"
                                :id="`contracts[${index}][hangar]`"
                                :name="`contracts[${index}][hangar]`"
                                v-model="input.hangar"
                            >
                                <option value="">{{ labels.select }}</option>
                                <option
                                    v-for="hangar in input.hangars_list"
                                    :key="hangar.id"
                                    :value="hangar.id"
                                >
                                    {{ hangar.name }}
                                </option>
                            </select>
                            <label :for="`contracts[${index}][hangar]`"
                                >Hangar</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <div class="form-floating">
                        <input
                            type="date"
                            class="form-control"
                            required="required"
                            :id="`contracts[${index}][date]`"
                            :name="`contracts[${index}][date]`"
                            v-model="input.date"
                            :placeholder="labels.date"
                        />
                        <label :for="`contracts[${index}][date]`">{{
                            labels.date
                        }}</label>
                    </div>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <div class="form-floating">
                        <select
                            class="form-control"
                            required="required"
                            :id="`contracts[${index}][index]`"
                            :name="`contracts[${index}][index]`"
                            v-model="input.index"
                        >
                            <option value="">{{ labels.select }}</option>
                            <option value="igpdi">IGPDI</option>
                            <option value="ipca">IPCA</option>
                        </select>
                        <label :for="`contracts[${index}][index]`">{{
                            labels.index
                        }}</label>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label
                        :for="`contracts[${index}][file]`"
                        class="form-label"
                        >{{ labels.file }}</label
                    >
                    <input
                        class="form-control"
                        type="file"
                        :id="`contracts[${index}][file]`"
                        :name="`contracts[${index}][file]`"
                        :placeholder="labels.file"
                        :required="input.id == ''"
                        autofocus="true"
                    />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :name="`contracts[${index}][unique_price]`"
                                    :id="`contracts[${index}][unique_price]`"
                                    v-model="input.unique_price"
                                />
                                <label
                                    class="form-check-label"
                                    :for="`contracts[${index}][unique_price]`"
                                >
                                    {{ labels.specific_price }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12" v-if="input.unique_price">
                            <div class="row">
                                <div
                                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                >
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            required="required"
                                            :id="
                                                `contracts[${index}][price_real]`
                                            "
                                            :name="
                                                `contracts[${index}][price_real]`
                                            "
                                            v-model="input.price_real"
                                            :placeholder="`Real (R$)`"
                                        />
                                        <label
                                            :for="
                                                `contracts[${index}][price_real]`
                                            "
                                            >Real (R$)</label
                                        >
                                    </div>
                                </div>
                                <div
                                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                >
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            required="required"
                                            :id="
                                                `contracts[${index}][price_dolar]`
                                            "
                                            :name="
                                                `contracts[${index}][price_dolar]`
                                            "
                                            v-model="input.price_dolar"
                                            :placeholder="
                                                labels.dollar + ` (U$)`
                                            "
                                        />
                                        <label
                                            :for="
                                                `contracts[${index}][price_dolar]`
                                            "
                                            >{{ labels.dollar }} (U$)</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :name="
                                        `contracts[${index}][quantity_boolean]`
                                    "
                                    :id="
                                        `contracts[${index}][quantity_boolean]`
                                    "
                                    v-model="input.quantity_boolean"
                                />
                                <label
                                    class="form-check-label"
                                    :for="
                                        `contracts[${index}][quantity_boolean]`
                                    "
                                >
                                    {{ labels.specific_price_quantity }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12" v-if="input.quantity_boolean">
                            <div class="row">
                                <div
                                    class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                                >
                                    <div class="form-floating">
                                        <input
                                            type="number"
                                            class="form-control"
                                            required="required"
                                            :id="
                                                `contracts[${index}][quantity]`
                                            "
                                            :name="
                                                `contracts[${index}][quantity]`
                                            "
                                            v-model="input.quantity"
                                            :placeholder="labels.quantity"
                                        />
                                        <label
                                            :for="
                                                `contracts[${index}][quantity]`
                                            "
                                            >{{ labels.quantity }}</label
                                        >
                                    </div>
                                </div>
                                <div
                                    class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                                >
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            required="required"
                                            :id="
                                                `contracts[${index}][price_real_quantity]`
                                            "
                                            :name="
                                                `contracts[${index}][price_real_quantity]`
                                            "
                                            v-model="input.price_real_quantity"
                                            :placeholder="`Real (R$)`"
                                        />
                                        <label
                                            :for="
                                                `contracts[${index}][price_real_quantity]`
                                            "
                                            >Real (R$)</label
                                        >
                                    </div>
                                </div>
                                <div
                                    class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                                >
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            required="required"
                                            :id="
                                                `contracts[${index}][price_dolar_quantity]`
                                            "
                                            :name="
                                                `contracts[${index}][price_dolar_quantity]`
                                            "
                                            v-model="input.price_dolar_quantity"
                                            :placeholder="
                                                labels.dollar + ` (U$)`
                                            "
                                        />
                                        <label
                                            :for="
                                                `contracts[${index}][price_dolar_quantity]`
                                            "
                                            >{{ labels.dollar }} (U$)</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                    <button
                        v-if="index === 0"
                        type="button"
                        class="btn btn-primary form-control"
                        @click="addField(forms)"
                    >
                        <i class="fas fa-plus"></i> {{ labels.contract }}
                    </button>

                    <button
                        v-else
                        type="button"
                        class="btn btn-danger form-control text-white"
                        @click="removeField(index, forms)"
                    >
                        <i class="fas fa-times fa-lg"></i> {{ labels.contract }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        dataContracts: {
            type: Array
        },
        tables: {
            type: Array,
            default: []
        },
        airports: {
            type: Array,
            default: []
        },
        labels: {
            type: Object,
            default: {}
        }
    },
    created() {
        if (this.dataContracts) {
            this.dataContracts.map((contract, key) => {
                this.forms[key].id = contract.id;
                this.forms[key].name = contract.name;
                this.forms[key].price_table = contract.price_table_id;
                this.forms[key].icao = contract.icao_id;

                this.forms[key].date = this.format_date(contract.date);
                this.forms[key].index = contract.index;

                this.forms[key].unique_price = contract.is_unique_price;
                this.forms[key].price_real = contract.price_real;
                this.forms[key].price_dolar = contract.price_dolar;

                this.forms[key].quantity_boolean = contract.is_quantity_boolean;
                this.forms[key].quantity = contract.quantity;
                this.forms[key].price_real_quantity =
                    contract.price_real_quantity;
                this.forms[key].price_dolar_quantity =
                    contract.price_dolar_quantity;

                this.getHangars(this.forms[key], key);

                this.forms[key].hangar = contract.hangar_id;
            });
        }
    },
    data() {
        return {
            forms: [
                {
                    id: "",
                    name: "",
                    price_table: "",
                    icao: "",
                    hangar: "",
                    date: "",
                    index: "",

                    unique_price: false,
                    price_real: "",
                    price_dolar: "",

                    quantity_boolean: false,
                    quantity: "",
                    price_real_quantity: "",
                    price_dolar_quantity: "",

                    hangars_list: []
                }
            ]
        };
    },
    methods: {
        addField(fieldType) {
            fieldType.push({
                id: "",
                name: "",
                price_table: "",
                icao: "",
                hangar: "",
                date: "",
                index: "",

                unique_price: false,
                price_real: "",
                price_dolar: "",

                quantity_boolean: false,
                quantity: "",
                price_real_quantity: "",
                price_dolar_quantity: "",

                hangars_list: []
            });
        },
        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },
        getHangars(input, index) {
            input.hangar = "";
            const params = { airport: input.icao };
            axios.post("/api/v1/hangars", params).then(response => {
                const valor = response.data;
                this.forms[index].hangars_list = valor;
            });
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD");
            }
        }
    }
};
</script>
