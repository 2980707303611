var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "form-floating mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.amount,
              expression: "amount",
              modifiers: { number: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "number",
            id: _vm.idAmount,
            name: _vm.nameAmount,
            placeholder: _vm.labelAmount
          },
          domProps: { value: _vm.amount },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.amount = _vm._n($event.target.value)
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.idAmount } }, [
          _vm._v(_vm._s(_vm.labelAmount))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.amount, function(index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-2"
            },
            [
              _c("div", { staticClass: "form-floating mb-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["AAAAA"],
                      expression: "['AAAAA']"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.input[index],
                      expression: "input[index]"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "prefix[" + index + "]",
                    name: "prefix[" + index + "]",
                    placeholder: _vm.labelPrefix
                  },
                  domProps: { value: _vm.input[index] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.input, index, $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "prefix[" + index + "]" } }, [
                  _vm._v(_vm._s(_vm.labelPrefix))
                ])
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }