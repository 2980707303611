var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticStyle: { height: "calc(80% - 30px)" } }, [
    _c("form", { staticClass: "form", on: { change: _vm.getValor } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-12 col-md-3 mb-3" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "type" } }, [
            _vm._v(_vm._s(_vm.labels.chartType))
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.type,
                  expression: "form.type"
                }
              ],
              staticClass: "form-select",
              attrs: { name: "type" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "currency" } }, [
                _vm._v(_vm._s(_vm.labels.currency))
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "liters" } }, [
                _vm._v(_vm._s(_vm.labels.liters))
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-12 col-md-3 mb-3" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "airport" } },
            [_vm._v(_vm._s(_vm.labels.airports))]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.airport,
                  expression: "form.airport"
                }
              ],
              staticClass: "form-select",
              attrs: { name: "airport" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "airport",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "" } }, [
                _vm._v(_vm._s(_vm.labels.all))
              ]),
              _vm._v(" "),
              _vm._l(_vm.airports, function(airport) {
                return _c(
                  "option",
                  { key: airport.id, domProps: { value: airport.id } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(airport.name) +
                        "\n                    "
                    )
                  ]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-12 col-md-3 mb-3" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "month" } }, [
            _vm._v(_vm._s(_vm.labels.month))
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.month,
                  expression: "form.month"
                }
              ],
              staticClass: "form-select",
              attrs: { name: "month" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "month",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.labels.months, function(month, index) {
              return _c("option", { key: index, domProps: { value: index } }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(month) +
                    "\n                    "
                )
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-12 col-md-3 mb-3" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "year" } }, [
            _vm._v(_vm._s(_vm.labels.year))
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.year,
                  expression: "form.year"
                }
              ],
              staticClass: "form-select",
              attrs: { name: "year" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "year",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.theDates, function(year) {
              return _c("option", { key: year, domProps: { value: year } }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(year) +
                    "\n                    "
                )
              ])
            }),
            0
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { style: _vm.styles },
      [
        _c("vue-speedometer", {
          attrs: {
            currentValueText: _vm.theLabelV,
            fluidWidth: true,
            maxSegmentLabels: 4,
            segments: 75,
            minValue: 0,
            maxValue: 1000,
            value: _vm.theValue,
            needleTransitionDuration: 4000,
            needleTransition: "easeElastic"
          },
          on: {
            "update:value": function($event) {
              _vm.theValue = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }