<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
        <div class="row">
          <div class="col-4">Peso de Aeronave</div>
          <div class="col-4">Valor em U$ para aeronaves internacionais</div>
          <div class="col-4">Valor em R$ para aeronaves nacionais</div>
        </div>
      </div>
    </div>
    <div
      class="row mb-3"
      v-for="(input, index) in forms"
      :key="`line-${index}`"
    >
      <div class="col-12">
        
        <div class="row">
          <div class="col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            
            <div class="row">
              <div class="col-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    :id="`prices[${index}][peso]`"
                    :name="`prices[${index}][peso]`"
                    v-model="input.peso"
                    :placeholder="`Peso`"
                  />
                  <label :for="`prices[${index}][peso]`">Peso</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating">
                  <input
                    type="number"
                    class="form-control"
                    :id="`prices[${index}][usd]`"
                    :name="`prices[${index}][usd]`"
                    v-model="input.usd"
                    :placeholder="`USD`"
                  />
                  <label :for="`prices[${index}][usd]`">USD</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating">
                  <input
                    type="number"
                    class="form-control"
                    :id="`prices[${index}][brl]`"
                    :name="`prices[${index}][brl]`"
                    v-model="input.brl"
                    :placeholder="`BRL`"
                  />
                  <label :for="`prices[${index}][brl]`">BRL</label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 form-inline d-grid gap-2 d-flex justify-content-md-end"
          >
            <button
              v-if="index === 0"
              class="btn btn-primary"
              type="button"
              @click="addField(forms)"
            >
              <i class="fas fa-plus"></i>
            </button>
            <button
              v-else
              class="btn btn-danger"
              type="button"
              @click="removeField(input, forms)"
            >
              <i class="fas fa-times fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataPrices: {
      type: Array,
    },
  },
  created() {
    if (this.dataPrices) {
      this.dataPrices.map((price, key) => {
        this.forms[key] = {
          peso: price.peso,
          usd: price.usd,
          brl: price.brl,
        };
      });
    }
  },
  data() {
    return {
      forms: [{ peso: "", usd: "", brl: "" }],
    };
  },
  methods: {
    addField(fieldType) {
      fieldType.push({ peso: "", usd: "", brl: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
  },
};
</script>
