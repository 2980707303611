<template>
    <section style="height: calc(80% - 30px);">
        <form class="form" @change="getValor">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-3 mb-3">
                    <label for="type" class="form-label">{{
                        labels.chartType
                    }}</label>
                    <select name="type" class="form-select" v-model="form.type">
                        <option value="currency">{{ labels.currency }}</option>
                        <option value="liters">{{ labels.liters }}</option>
                    </select>
                </div>
                <div class="col-12 col-sm-12 col-md-3 mb-3">
                    <label for="airport" class="form-label">{{
                        labels.airports
                    }}</label>
                    <select
                        name="airport"
                        class="form-select"
                        v-model="form.airport"
                    >
                        <option value="">{{ labels.all }}</option>
                        <option
                            v-for="airport in airports"
                            :key="airport.id"
                            :value="airport.id"
                        >
                            {{ airport.name }}
                        </option>
                    </select>
                </div>
                <div class="col-12 col-sm-12 col-md-3 mb-3">
                    <label for="month" class="form-label">{{
                        labels.month
                    }}</label>
                    <select
                        name="month"
                        class="form-select"
                        v-model="form.month"
                    >
                        <option
                            v-for="(month, index) in labels.months"
                            :key="index"
                            :value="index"
                        >
                            {{ month }}
                        </option>

                        
                    </select>
                </div>

                <div class="col-12 col-sm-12 col-md-3 mb-3">
                    <label for="year" class="form-label">{{
                        labels.year
                    }}</label>
                    <select name="year" class="form-select" v-model="form.year">
                        <option
                            v-for="year in theDates"
                            :key="year"
                            :value="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </div>
            </div>
        </form>
        <div :style="styles">
            <vue-speedometer
                :currentValueText="theLabelV"
                :fluidWidth="true"
                :maxSegmentLabels="4"
                :segments="75"
                :minValue="0"
                :maxValue="1000"
                :value.sync="theValue"
                :needleTransitionDuration="4000"
                needleTransition="easeElastic"
            />
        </div>
    </section>
</template>
<script>
import { stringify } from "querystring";
import VueSpeedometer from "vue-speedometer";

export default {
    components: { VueSpeedometer },

    props: {
        value: {
            type: Number,
            default: 0
        },
        airports: {
            type: Array,
            default: []
        },
        dates: {
            type: Array,
            default: function() {
                return [];
            }
        },
        labelValue: {
            type: String,
            default: ""
        },
        labels: {
            type: Object,
            default: {}
        }
    },

    data() {
        const current = new Date();

        for (let i = Number(current.getFullYear()); i >= 2020; i--) {
            this.dates.push(i);
        }

        return {
            theValue: this.value,
            theDates: this.dates,
            theLabelV: this.labelValue,
            form: {
                type: "currency",
                airport: "",
                month: current.getMonth() + 1,
                year: Number(current.getFullYear())
            },
            styles: {
                width: "100%",
                height: "100%"
            }
        };
    },

    mounted() {
        this.getValor();
    },

    methods: {
        setValue(valor, type) {
            this.theValue = valor;
            if (type === "currency")
                this.theLabelV = `R$ ${this.theValue.toFixed(2)}`;
            else this.theLabelV = `${this.theValue.toFixed(2)} l`;
        },

        getValor() {
            const params = this.form;

            axios.post("/api/v1/fuels", params).then(response => {
                const valor = response.data;
                this.setValue(Number(valor.value), params.type);
            });
        }
    }
};
</script>
