<template>
  <div id="app">
    <vue-table
      :tbody-data="products"
      :headers="headers"
      :custom-options="customOptions"
      :style-wrap-vue-table="styleWrapVueTable"
      :disable-cells="disableCells"
      :disable-sort-thead="disableSortThead"
      :loading="loading"
      :parent-scroll-element="parentScrollElement"
      :select-position="selectPosition"
      :submenu-tbody="submenuTbody"
      :submenu-thead="submenuThead"
      v-on:tbody-change-data="changeData"
      v-on:tbody-submenu-click-change-color="changeColorTbody"
      v-on:tbody-submenu-click-change-value="changeValueTbody"
      v-on:thead-submenu-click-change-color="changeColorThead"
      v-on:thead-submenu-click-change-value="changeValueThead"
      v-on:thead-td-sort="sortProduct"
    >
      <div slot="header">Specific Header</div>
      <div slot="loader">Loader</div>
    </vue-table>
  </div>
</template>

<script>
import VueTable from "vuejs-spreadsheet";

export default {
  name: "app",
  props: {
    lang: {
      type: Object,
      default: {
        lang: "pt",
        en: {
          select: {
            placeholder: "Search by typing",
          },
        },
        fr: {
          select: {
            placeholder: "Taper pour chercher",
          },
        },
      },
    },
  },
  data() {
    return {
      customOptions: {
        tbodyIndex: true,
        sortHeader: true,
        trad: this.lang,
        newData: {
          type: "input",
          value: "",
          active: false,
          style: {
            color: "#000",
          },
        },
        fuseOptions: {
          shouldSort: true,
          threshold: 0.2,
          location: 0,
          distance: 30,
          maxPatternLength: 64,
          minMatchCharLength: 1,
          findAllMatches: false,
          tokenize: false,
          keys: ["value"],
        },
      },
      submenuTbody: [
        {
          type: "button",
          value: "change color",
          function: "change-color",
          disabled: ["img"],
        },
        {
          type: "button",
          value: "change value",
          function: "change-value",
          disabled: ["img", "name"],
        },
      ],
      submenuThead: [
        {
          type: "button",
          value: "change color",
          function: "change-color",
          disabled: ["a"],
        },
        {
          type: "select",
          disabled: ["a"],
          subtitle: "Select state:",
          selectOptions: [
            {
              value: "new-york",
              label: "new-york",
            },
            {
              value: "france",
              label: "france",
            },
          ],
          value: "new-york",
          buttonOption: {
            value: "change city",
            function: "change-city",
            style: {
              display: "block",
            },
          },
        },
        {
          type: "button",
          value: "change value",
          function: "change-value",
          disabled: ["a", "b"],
        },
      ],
      disableCells: ["a"],
      loading: true,
      parentScrollElement: {
        attribute: "html",
        positionTop: 0,
      },
      selectPosition: {
        top: 0,
        left: 0,
      },
      disableSortThead: ["a"],
      styleWrapVueTable: {
        fontSize: "12px",
        comment: {
          borderColor: "#696969",
          borderSize: "8px",
          widthBox: "120px",
          heightBox: "80px",
        },
      },
      headers: [
        {
          headerName: "Weight",
          headerKey: "weight",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "Price",
          headerKey: "price",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
      ],
      products: [],
    };
  },
  components: {
    VueTable,
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },
  methods: {
    changeData(row, header) {
      console.log(row, header);
    },
    sortProduct(event, header, colIndex) {
      console.log("sort product");
    },
    // callback
    changeColorThead(event, header, colIndex) {
      this.headers[colIndex].style.color = "#e40000";
    },
    changeColorTbody(event, header, rowIndex, colIndex) {
      this.products[rowIndex][header].style = {};
      this.products[rowIndex][header].style.color = "#e40000";
    },
    changeValueTbody(event, header, rowIndex, colIndex) {
      this.products[rowIndex][header].value = "T-shirt";
    },
    changeValueThead(event, entry, colIndex) {
      this.headers[colIndex].headerName = "T-shirt";
    },
  },
};
</script>

<style lang="scss">
::-moz-selection {
  color: #2c3e50;
  background: transparent;
}
::selection {
  color: #2c3e50;
  background: transparent;
}
</style>
