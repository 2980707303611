<template>
    <div>
        <input
            type="hidden"
            :id="`user_id`"
            :name="`user_id`"
            v-model="forms.user_id"
            :placeholder="labels.user_id"
        />
        <div class="row mb-3">
            <div class="col-12">
                <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        required="required"
                        :id="`document`"
                        :name="`document`"
                        v-model="forms.document"
                        :placeholder="labels.document"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        @change="getCompany()"
                    />
                    <label :for="`document`">{{ labels.document }}</label>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        required="required"
                        :id="`company`"
                        :name="`company`"
                        v-model="forms.company"
                        :placeholder="labels.company"
                    />
                    <label :for="`company`">{{ labels.company }}</label>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="form-floating">
                    <select
                        class="form-control"
                        required="required"
                        :id="`role`"
                        :name="`role`"
                        v-model="forms.role"
                    >
                        <option value="">{{ labels.select }}</option>
                        <option
                            v-for="role in roles"
                            :key="role.id"
                            :value="role.id"
                        >
                            {{ role.name }}
                        </option>
                    </select>

                    <label :for="`role`">{{ labels.role }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataUser: {
            type: Object,
            default: {}
        },
        labels: {
            type: Object,
            default: {}
        },
        roles: {
            type: Array,
            default: []
        }
    },
    created() {
        if (this.dataUser) {
            this.forms = {
                document: this.dataUser.company.document,
                company: this.dataUser.name,
                role: this.dataUser.role.id,
                user_id: this.dataUser.id
            }
        }
    },
    data() {
        return {
            forms: {
                document: "",
                company: "",
                role: "",
                user_id: ""
            }
        };
    },
    methods: {
        getCompany() {
            var params = { document: this.forms.document };
            axios.post("/api/v1/company", params).then(response => {
                const valor = response.data;
                this.forms.company = valor.name;
                this.forms.role = valor.role_id;
                this.forms.user_id = valor.id;
            });
        }
    }
};
</script>
