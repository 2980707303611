var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-floating" },
    [
      _c(
        "money",
        _vm._b(
          {
            staticClass: "form-control",
            attrs: { required: "required", name: "price" },
            model: {
              value: _vm.formatPrice,
              callback: function($$v) {
                _vm.formatPrice = $$v
              },
              expression: "formatPrice"
            }
          },
          "money",
          _vm.money,
          false
        )
      ),
      _vm._v(" "),
      _c("label", { attrs: { for: "price" } }, [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }