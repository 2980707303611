<template>
  <div class="row">
    <div class="col-12">
      <div class="form-floating mb-3">
        <input
          type="number"
          class="form-control"
          :id="idAmount"
          :name="nameAmount"
          v-model.number="amount"
          :placeholder="labelAmount"
        />
        <label :for="idAmount">{{ labelAmount }}</label>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-2"
          v-for="index in amount"
          :key="index"
        >
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              :id="`prefix[${index}]`"
              :name="`prefix[${index}]`"
              v-mask="['AAAAA']"
              :placeholder="labelPrefix"
              v-model="input[index]"
            />
            <label :for="`prefix[${index}]`">{{ labelPrefix }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelAmount: {
      type: String,
      required: true,
    },
    valueAmount: {
      type: String,
    },
    idAmount: {
      type: String,
      required: true,
    },
    nameAmount: {
      type: String,
      required: true,
    },

    labelPrefix: {
      type: String,
      required: true,
    },
    valuePrefix: {
      type: Array,
    },
    // idPrefix: {
    //   type: String,
    //   required: true,
    // },
    // namePrefix: {
    //   type: String,
    //   required: true,
    // },
  },
  created() {
    if (this.valueAmount) {
      this.amount = parseInt(this.valueAmount);
    }
    if (this.valuePrefix) {
      this.valuePrefix.map(({ prefix }, key) => {
        this.input[key + 1] = prefix;
      });
    }
  },
  data() {
    return {
      amount: "",
      input: [],
    };
  },
};
</script>
