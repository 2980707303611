<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="form-floating mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :id="prefixId"
                            :name="prefixName"
                            v-mask="['AAAAA']"
                            v-model="prefix"
                            autofocus
                        />
                        <label :for="prefixId">{{ prefixLabel }}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                    <button
                        class="btn btn-primary w-100 h-100"
                        type="button"
                        @click="getRAB"
                        v-bind:disabled="loading"
                    >
                        <template v-if="loading">
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Loading</span>
                        </template>
                        <template v-else>
                            <span>Pesquisar</span>
                        </template>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-floating mb-3">
                        <input
                            disabled
                            :id="weightId"
                            type="text"
                            class="form-control"
                            :name="weightName"
                            v-model="informations.peso_maximo_de_decolagem"
                            :placeholder="weightLabel"
                        />
                        <label :for="weightId">{{ weightLabel }}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-floating mb-3">
                        <input
                            disabled
                            :id="ownerId"
                            type="text"
                            class="form-control"
                            :name="ownerName"
                            v-model="informations.proprietario"
                            :placeholder="ownerLabel"
                        />
                        <label :for="ownerId">{{ ownerLabel }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-floating mb-3">
                        <input
                            disabled
                            :id="chartsId"
                            type="text"
                            class="form-control"
                            :name="chartsName"
                            v-model="chartdata.name"
                            placeholder="chartsName"
                        />
                        <label :for="chartsId">{{ chartsLabel }}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-floating mb-3">
                        <input
                            disabled
                            :id="chartsRealId"
                            type="text"
                            class="form-control"
                            :name="chartsRealName"
                            v-model="chartdata.price_real"
                            :placeholder="chartsRealLabel"
                        />
                        <label :for="chartsRealId">{{ chartsRealLabel }}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-floating mb-3">
                        <input
                            disabled
                            :id="chartsDolarId"
                            type="text"
                            class="form-control"
                            :name="chartsDolarName"
                            v-model="chartdata.price_dolar"
                            :placeholder="chartsDolarLabel"
                        />
                        <label :for="chartsDolarId">{{
                            chartsDolarLabel
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" name="charts" v-model="chartdata.uuid" />
        <input
            type="hidden"
            name="airport[peso_maximo_de_decolagem]"
            v-model="informations.peso_maximo_de_decolagem"
        />
        <input
            type="hidden"
            name="airport[proprietario]"
            v-model="informations.proprietario"
        />
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        prefixName: {
            type: String
        },
        prefixLabel: {
            type: String
        },
        prefixValue: {
            type: String
        },
        prefixId: {
            type: String
        },
        prefixError: {
            type: String
        },
        prefixSuccess: {
            type: String
        },

        ownerName: {
            type: String
        },
        ownerLabel: {
            type: String
        },
        ownerValue: {
            type: String
        },
        ownerId: {
            type: String
        },

        weightName: {
            type: String
        },
        weightLabel: {
            type: String
        },
        weightValue: {
            type: String
        },
        weightId: {
            type: String
        },

        // UUID Hangar and Contract
        contractUuid: {
            type: String,
            required: true
        },
        hangarUuid: {
            type: String,
            required: true
        },

        // Charts
        chartsId: {
            type: String
        },
        chartsName: {
            type: String
        },
        chartsLabel: {
            type: String
        },

        // Real
        chartsRealId: {
            type: String
        },
        chartsRealName: {
            type: String
        },
        chartsRealLabel: {
            type: String
        },

        // Dolar
        chartsDolarId: {
            type: String
        },
        chartsDolarName: {
            type: String
        },
        chartsDolarLabel: {
            type: String
        },
        chartsValues: {
            type: Object
        }
    },
    async created() {
        if (
            this.prefixValue ||
            this.weightValue ||
            this.ownerValue ||
            this.chartsValues
        ) {
            this.prefix = this.prefixValue;
            this.informations.peso_maximo_de_decolagem = `${this.weightValue} - Kg`;
            this.informations.proprietario = `${this.ownerValue}`;
            (this.chartdata = this.chartsValues),
                await this.getPriceByWeight(this.prefixValue);
        }
    },
    data() {
        return {
            loading: false,
            error: false,
            success: false,
            prefix: null,
            hangarUUID: this.hangarUuid,
            contractUUID: this.contractUuid,
            informations: {
                peso_maximo_de_decolagem: "",
                proprietario: ""
            },
            chartdata: {
                uuid: "",
                name: "",
                weight_min: null,
                weight_max: null,
                price_real: "",
                price_dolar: ""
            }
        };
    },
    methods: {
        getRAB: async function() {
            this.success = false;
            this.error = false;
            if (this.loading) {
                return;
            }
            this.loading = true;
            try {
                const { data } = await axios({
                    url: "/rab",
                    method: "post",
                    baseURL: "http://127.0.0.1:8000/api/v1",
                    data: {
                        code: this.prefix
                    }
                });

                this.success = true;
                this.informations = data;
                await this.getPriceByWeight(
                    this.informations.peso_maximo_de_decolagem.replace(
                        /\D/g,
                        ""
                    )
                );

                this.loading = false;
            } catch (error) {
                console.error(error);
                this.error = true;
                this.loading = false;
                this.clearInformations();
            }
        },
        clearInformations: function() {
            this.informations = {
                peso_maximo_de_decolagem: "",
                proprietario: ""
            };
        },
        getPriceByWeight: async function(weight) {
            if (!this.loading) {
                return;
            }
            try {
                const { data } = await axios({
                    url: "/price",
                    method: "post",
                    baseURL: "http://127.0.0.1:8000/api/v1",
                    data: {
                        contract: this.contractUUID,
                        hangar: this.hangarUUID,
                        weight: weight
                    }
                });
                this.chartdata = data;
            } catch (error) {
                console.error(error);
                this.chartdata = {};
            }
        }
    }
};
</script>
