<template>
  <div class="form-floating">
    <money
      v-model="formatPrice"
      v-bind="money"
      class="form-control"
      required="required"
      name="price"
    />
    <label for="price">{{ label }}</label>
  </div>
</template>

<script>
import { Money } from "v-money";

export default {
  components: { Money },

  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      formatPrice: this.price,
      money: {
        decimal: ".",
        thousands: "",
        precision: 2,
        masked: true,
      },
    };
  },
};
</script>
